let stripes = {}
let stripeElements = {}

// The StripeElements library shows the following disclaimer when rendering the payment form: 'By
// providing your card information, you allow **company-name** to charge your card for future
// payments in accordance with their terms.'
//
// This is controlled by the `terms` object in the API. We want to disable all disclaimers for all
// payment methods in Stripe.
//
// List taken from https://stripe.com/docs/js/elements_object/create_payment_element#payment_element_create-options-terms
const terms = {
  applePay: 'never',
  auBecsDebit: 'never',
  bancontact: 'never',
  card: 'never',
  cashapp: 'never',
  googlePay: 'never',
  ideal: 'never',
  paypal: 'never',
  sepaDebit: 'never',
  sofort: 'never',
  usBankAccount: 'never',
}

// Returns a Stripe object for the passed publishable key.
async function getStripe(publishableKey) {
  if (stripes[publishableKey]) {
    return stripes[publishableKey]
  }

  let Stripe = await getStripeClass()

  stripes[publishableKey] = Stripe(publishableKey)
  return stripes[publishableKey]
}

async function getStripeElements(publishableKey, clientSecret) {
  const elementsCacheKey = publishableKey + clientSecret
  if (stripeElements[elementsCacheKey]) {
    return stripeElements[elementsCacheKey]
  }
  let stripe = await getStripe(publishableKey)
  stripeElements[elementsCacheKey] = stripe.elements({
    clientSecret: clientSecret,
  })
  return stripeElements[elementsCacheKey]
}

function getStripeClass() {
  return new Promise((resolve) => {
    if (typeof Stripe !== 'undefined') {
      resolve(window.Stripe)
      return
    }

    let script = document.createElement('script')
    script.onload = () => {
      resolve(window.Stripe)
    }
    script.src = 'https://js.stripe.com/v3/'
    document.head.appendChild(script)
  })
}

export async function initializeStripeElement(
  publishableKey,
  clientSecret,
  targetSelector
) {
  const target = document.querySelector(targetSelector)
  const defaultValues = getDefaultValues()
  let elements = await getStripeElements(publishableKey, clientSecret)

  const fieldsBillingDetailsConfig = {
    name: 'never',
    email: 'never',
  }

  // Check the field with the country code selector is actually present in the form
  let countryCodeField
  if (target.dataset.countryCodeSelector) {
    countryCodeField = document.querySelector(
      target.dataset.countryCodeSelector
    )
  }
  if (countryCodeField) {
    fieldsBillingDetailsConfig.address = {
      country: 'never',
    }
  }

  if (target.dataset.postalCodeSelector) {
    fieldsBillingDetailsConfig.address =
      fieldsBillingDetailsConfig.address || {}
    fieldsBillingDetailsConfig.address.postalCode = 'never'
  }

  let paymentElement =
    elements.getElement('payment') ||
    elements.create('payment', {
      defaultValues: defaultValues,
      terms: terms,
      fields: {
        billingDetails: fieldsBillingDetailsConfig,
      },
    })
  paymentElement.mount(targetSelector)

  paymentElement.on('ready', () => {
    // If we are setting a new card element for the user to enter a new card,
    // we want to add a class that marks it as not completed, to prevent the form from being submitted,
    // lowering the amount of abandoned purchases.
    // This class will be removed once the user fills in all the required card details.
    if (targetSelector == '#stripe_card_element') {
      target.classList.add('js-stripe-is-not-completed')
    }

    target.dispatchEvent(new CustomEvent('stripe:ready'))
  })

  paymentElement.on('change', (event) => {
    const stripeCardElement = document.querySelector('#stripe_card_element')
    if (event.complete) {
      stripeCardElement.classList.remove('js-stripe-is-not-completed')
    } else {
      stripeCardElement.classList.add('js-stripe-is-not-completed')
    }
  })
}

function getDefaultValues() {
  const stripeFieldsElement = document.querySelector('#stripe_fields')

  return {
    billingDetails: {
      address: {
        country: stripeFieldsElement.dataset.addressCountry,
        postal_code: stripeFieldsElement.dataset.addressPostalCode,
      },
    },
  }
}

/* Handles a client-side submit for a new card. Note this requires Stripe Elements to be mounted if using a payment
   method that requires it. */
export async function submitToStripe(
  publishableKey,
  clientSecret,
  successUrl,
  setupIntent,
  subtype,
  name,
  email,
  selector
) {
  let stripe = await getStripe(publishableKey)

  if (subtype == 'bancontact') {
    /* No Stripe elements for Bancontact, we get right to calling Stripe, which will either return an error or redirect
       away. */
    const { error } = await stripe.confirmBancontactPayment(clientSecret, {
      payment_method: {
        billing_details: {
          name: name,
          email: email,
        },
      },
      return_url: successUrl,
    })

    return error
  }

  let elements = await getStripeElements(publishableKey, clientSecret)

  const billing_details = {
    name,
    email,
  }

  const countryCodeSelector =
    document.querySelector(selector).dataset.countryCodeSelector
  const countryField = document.querySelector(countryCodeSelector)
  if (countryField) {
    billing_details.address = {
      country: document.querySelector(countryCodeSelector).value,
    }
  }

  const postalCodeSelector =
    document.querySelector(selector).dataset.postalCodeSelector
  if (postalCodeSelector) {
    billing_details.address = billing_details.address || {}
    billing_details.address.postal_code =
      document.querySelector(postalCodeSelector).value
  }

  let confirmParams = {
    return_url: successUrl,
    payment_method_data: {
      billing_details,
    },
  }

  if (setupIntent) {
    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: confirmParams,
    })

    return error
  }

  const { error } = await stripe.confirmPayment({
    elements,
    confirmParams: confirmParams,
  })

  return error
}
window.submitToStripe = submitToStripe

/* Handles a client-side submit for a saved card. Note this does not require Stripe Elements to be mounted. */
async function confirmExisting3DS(
  publishableKey,
  clientSecret,
  paymentMethodId,
  successUrl
) {
  let stripe = await getStripe(publishableKey)

  let confirmParams = {
    payment_method: paymentMethodId,
    return_url: successUrl,
  }

  return stripe.confirmCardPayment(clientSecret, confirmParams)
}

/* Handles Stripe client-side submit. Either navigates to the success URL and returns null or returns a String error. */
export async function handleClientSideSubmitResult(
  json,
  publishableKey,
  subtype,
  customerName,
  customerEmail
) {
  if (json.success) {
    // It worked, no client-side stuff required.
    location.href = json.callback_url
    return
  }

  if (!json.client_secret) {
    return 'Unknown error'
  }

  if (json.payment_method_id) {
    // A previously existing payment method that needs 3DS reconfirmation. This method does not require
    // Stripe Elements to be set up.
    return await confirmExisting3DS(
      publishableKey,
      json.client_secret,
      json.payment_method_id,
      json.callback_url
    ).then((result) => {
      if (result.paymentIntent) {
        location.href = json.callback_url
        return
      }
      console.log(result)
      return result.error.message
    })
  }

  // A new payment method that needs 3DS confirmation.
  return submitToStripe(
    publishableKey,
    json.client_secret,
    json.callback_url,
    json.intent_type,
    subtype,
    customerName,
    customerEmail,
    '#stripe_card_element'
  )
}
